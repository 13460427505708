<template>
  <query-builder
    v-model="valueMutation"
    :rules="builderRules"
    modelType="nomenclature"
  />
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  import QueryBuilder from '@/components/query-builder'
  import NomenclatureCategorySelect from '@/components/ModelSelect/NomenclatureCategorySelect'
  // import NomenclatureQueryBuilder from './NomenclatureQueryBuilder'

  export default {
    components: {
      QueryBuilder,
    },
    model: {
      prop: 'value',
      event: 'input',
    },
    props: {
      value: {
        type: Object,
        default: null,
      },
      isParent: { type: Boolean, default: false },
    },
    data () {
      return {
        loading: true,
      }
    },
    computed: {
      ...mapGetters({
        programId: 'programId',
        allNomenclatureFields: 'settings/nomenclature-fields/fields',
      }),
      valueMutation: {
        get: function () {
          return this.value
        },
        set: function (v) {
          this.$emit('input', v)
        },
      },
      nomenclatureFields () {
        return this.allNomenclatureFields[this.programId] || []
      },
      builderRules () {
        const baseRules = [
          {
            type: 'text',
            id: 'name',
            label: 'Наименование',
          },
          {
            type: 'text',
            id: 'description',
            label: 'Описание',
          },
          {
            type: 'text',
            id: 'uuid',
            label: 'UUID',
          },
          {
            type: 'numeric',
            id: 'sale_price',
            label: 'Цена продажи',
          },
          {
            type: 'select',
            id: 'type',
            label: 'Тип',
            choices: [
              { value: 'product', label: 'Продукт' },
              { value: 'service', label: 'Услуга' },
              { value: 'bundle', label: 'Комплект' },
              { value: 'variant', label: 'Модификация' },
              { value: 'consignment', label: 'Партия' },
            ],
          },
          {
            type: 'custom-component',
            id: 'category_uuid',
            component: NomenclatureCategorySelect,
            props: { selectFirst: true },
            label: 'Категория',
          },
        ].concat(this.nomenclatureFields.map((field) => {
          switch (field.type) {
            case 'integer':
            case 'float':
              return {
                type: 'numeric',
                label: field.name,
                id: `fields[${field.id}].program_nomenclatures_fields.value`,
              }
            case 'date':
              return {
                type: 'date',
                label: field.name,
                id: `fields[${field.id}].program_nomenclatures_fields.value`,
              }
            case 'datetime':
              return {
                type: 'datetime',
                label: field.name,
                id: `fields[${field.id}].program_nomenclatures_fields.value`,
              }
            default: return {
              type: 'text',
              label: field.name,
              id: `fields[${field.id}].program_nomenclatures_fields.value`,
            }
          }
        }))

        if (!this.isParent) {
          return baseRules.concat([{
            type: 'subquery',
            id: 'parent',
            rules: baseRules,
            label: 'Базовый товар',
          }])
        } else {
          return baseRules
        }

        // .concat(!this.isParent ? [{
        //     type: 'custom-component',
        //     id: 'parent',
        //     component: NomenclatureQueryBuilder,
        //     operators: ['has_query'],
        //     props: { isParent: true },
        //     label: 'Базовый товар',
        //   }] : [])
      },

    },
    watch: {

    },
    async mounted () {
      // this.loading = true
      // await this.getFields({ programId: this.programId, withStub: true })
      // this.loading = false
    },
    methods: {
      ...mapActions({
        getFields: 'settings/nomenclature-fields/GetFields',
      }),

    },
  }
</script>

<style lang="scss" scoped>

</style>
