<template>
  <v-row
    v-if="currentStep < 3"
    no-gutters
  >
    <v-col>
      <v-skeleton-loader
        :loading="loading"
        :style="{ height: '100%', width: '100%' }"
        type="card-heading, image@3"
      >
        <component
          :is="stepperComponent.name"
          v-bind="{...stepperComponent.props}"
          v-on="{...stepperComponent.on}"
        >
          <v-row
            justify="center"
            class="row__master-stepper"
            no-gutters
          >
            <v-col
              :cols="12"
              :sm="12"
              :md="11"
              :lg="10"
            >
              <v-tabs-items
                v-model="currentStep"
              >
                <v-tab-item
                  :value="0"
                >
                  <div style="height: 100%;">
                    <v-container class="container__main-step">
                      <v-row
                        align="center"
                        justify="center"
                      >
                        <v-col>
                          <v-form
                            ref="form"
                          >
                            <v-form
                              ref="form"
                            >
                              <BaseMasterFieldBlock title="Название">
                                <template v-slot:input>
                                  <base-text-field
                                    v-model="form.name"
                                    class="field-name"
                                    placeholder="Название"
                                    maxlength="255"
                                    :rules="rules.name"
                                    validation-placement="top"
                                    error-style="vuetify"
                                  />
                                </template>
                              </BaseMasterFieldBlock>
                              <BaseMasterFieldBlock title="Цвет">
                                <template v-slot:input>
                                  <div
                                    v-click-outside="closeColorPicker"
                                    class="field__color-picker"
                                  >
                                    <div
                                      class="field__color-box"
                                      :style="`background: ${form.color};`"
                                      @click="showColorPicker = !showColorPicker"
                                    />
                                    <div
                                      v-show="showColorPicker"
                                      class="colorPickerWrapper"
                                    >
                                      <v-color-picker
                                        v-model="form.color"
                                        class="color-picker pa-2"
                                      />
                                    </div>
                                  </div>
                                </template>
                              </BaseMasterFieldBlock>
                              <BaseMasterFieldBlock title="Описание">
                                <template v-slot:input>
                                  <base-text-area
                                    v-model="form.description"
                                    class="field-description"
                                    placeholder="Описание"
                                    :rules="rules.description"
                                    hide-details
                                    maxlength="1024"
                                  />
                                </template>
                              </BaseMasterFieldBlock>
                              <BaseMasterFieldBlock title="Тип сегмента">
                                <template v-slot:input>
                                  <v-radio-group
                                    v-model="form.type"
                                    class="field-type mt-0"
                                    hide-details
                                    :rules="rules.type"
                                    row
                                  >
                                    <v-radio
                                      :key="1"
                                      label="ручной"
                                      :value="1"
                                    />
                                    <v-radio
                                      :key="2"
                                      label="автоматический"
                                      :value="2"
                                    />
                                  </v-radio-group>
                                </template>
                              </BaseMasterFieldBlock>
                              <BaseMasterFieldBlock
                                v-if="form.type === 2"
                                title="Фильтр номенклатуры"
                              >
                                <template v-slot:input>
                                  <!-- <div>{{ form.filter }}</div> -->
                                  <nomenclature-query-builder
                                    v-model="form.filter"
                                  />
                                </template>
                              </BaseMasterFieldBlock>
                            </v-form>
                          </v-form>
                        </v-col>
                      </v-row>
                    </v-container>
                  </div>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </component>
      </v-skeleton-loader>
    </v-col>
  </v-row>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { deepClone } from '@/utils'
  import { isFilled, maxLen, isUnique } from '@/utils/validate'
  import QueryBuilder from '@/components/query-builder'
  import NomenclatureQueryBuilder from './NomenclatureQueryBuilder'
  // import NomenclatureCategorySelect from '@/components/ModelSelect/NomenclatureCategorySelect'
   import vClickOutside from 'v-click-outside'

  export default {
    directives: {
      clickOutside: vClickOutside.directive,
    },
    components: {
      QueryBuilder, NomenclatureQueryBuilder,
    },
    model: {
      prop: 'show',
      event: 'change',
    },
    props: {
      id: {
        type: Number,
        default: NaN,
      },
    },
    data () {
      const defaultForm = {
        name: '',
        description: '',
        color: '',
        type: 1,
        filter: {},
      }

      return {
        loading: false,
        loadingFetch: false,
        showColorPicker: false,
        defaultForm,
        form: Object.assign({}, defaultForm),
        rules: {
          name: [
            v => isFilled(v) || 'Обязательно для заполнения',
            v => maxLen(v, 255) || 'Не должно превышать 255 символов',
            v => isUnique(this.segmentListWithoutCurr, { name: v }) || 'Должно быть уникальным',
          ],
          description: [
            v => maxLen(v, 1024) || 'Не должно превышать 1024 символов',
          ],
        },

        currentStep: 0,
        stepperList: [
          { title: 'Общее' },
        ],
        pageList: [
          { id: '#main', name: 'Общее', route: { hash: '#main' } },
        ],
      }
    },
    computed: {
      ...mapGetters({
        programId: 'programId',
        segmentList: 'company/nomen_segment/list',
        segment: 'company/nomen_segment/segment',
      }),
      segmentListWithoutCurr () {
        if (this.isEdit) {
          return this.segmentList.filter(s => s.id !== this.id)
        }
        return this.segmentList
      },
      isEdit () {
        return !isNaN(this.id)
      },
      editSegment () {
        const segment = deepClone(this.segment)
        if (segment.type === 'manual') segment.filter = {}
        segment.type = segment.type === 'manual' ? 1 : 2
        return segment
      },
      stepperComponent () {
        return {
          name: 'base-top-menu',
          props: {
            menu: this.pageList,
            cancelButtonText: 'Закрыть',
            actionButtonText: this.isEdit ? 'Сохранить' : 'Создать',
            loading: this.loadingFetch,
            value: this.currentStep,
          },
          on: {
            cancelbutton: () => {
              this.$router.push({ name: 'NomenclatureSegments' })
            },
            actionbutton: () => {
              this.save()
            },
            change: v => {
              this.currentStep = v
            },
          },
        }
      },
    },
    watch: {
      'form.type' (v) {
        if (v === 1) {
          this.form.filter = {}
        }
      },
    },
    async mounted () {
      this.loading = true
      if (this.isEdit) {
        await this.fetchSegment(this.id)
        this.form = deepClone(this.editSegment)
      }
      await this.getFields({ programId: this.programId, withStub: true })
      this.loading = false
    },
    methods: {
      ...mapActions({
        fetchCreateSegment: 'company/nomen_segment/create',
        fetchUpdateSegment: 'company/nomen_segment/update',
        fetchSegment: 'company/nomen_segment/getSegment',
        getFields: 'settings/nomenclature-fields/GetFields',
      }),
      async save () {
        if (this.$refs.form.validate()) {
          const type = this.form.type === 1 ? 'manual' : 'auto'
          const color = this.form.color.length > 7 ? this.form.color.slice(0, -2) : this.form.color
          try {
            this.loadingFetch = true
            if (!this.isEdit) {
              const data = Object.assign({}, this.form, { program_id: this.programId, type, color })
              await this.fetchCreateSegment(data)
            } else {
              const data = Object.assign({}, this.form, { id: this.id, type, color })
              await this.fetchUpdateSegment(data)
            }
            this.$router.push({ name: 'NomenclatureSegments' })
          } finally {
            this.loadingFetch = false
          }
        } else {
          this.$notify({
            type: 'error',
            title: 'Сегменты',
            text: 'Ошибки валидации',
          })
        }
      },
      closeColorPicker () {
        this.showColorPicker = false
      },
    },
  }
</script>

<style lang="scss" scoped>
@import "@/styles/vuetify-preset-plus/light_theme/_variables.sass";

.master-field-block {
  &:last-child {
    margin-bottom: 34px;
  }
}

.field__color-picker {
  display: inline-block;
  position: relative;
  background-color: transparent;

  .field__color-box {
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 50px;
    background-color: $success;
    cursor: pointer;
  }

  .colorPickerWrapper {
    position: absolute;
    padding: 0px 7px 0px 0px;
    top: 38px;
    margin-left: -5px;
    border-radius: 6px;
    background: $neutral-100;
    z-index: 43;
    border: 3px solid #f6f6f6;

    &:before {
      content: '';
      position: absolute;
      left: 4px;
      top: -22px;
      border: 10px solid transparent;
      border-bottom: 10px solid #f6f6f6;
    }
  }
}

</style>
