<template>
  <v-autocomplete
    v-model="valueMutation"
    class=""
    :items="selectList"
    item-value="uuid"
    item-text="name"
    placeholder="Выберите категорию"
    outlined
    hide-details
    :loading="loadingAction"
    v-bind="$attrs"
    style="max-width:380px"
    v-on="$listeners"
  />
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    name: 'NomenclatureCategorySelect',
    components: {

    },
    model: {
      prop: 'value',
      event: 'update',
    },
    props: {
      value: { type: [String, Object, Array], default: undefined },
      programId: { type: [Number], default: undefined },
      selectFirst: { type: Boolean, default: false },
    },
    data () {
      return {
        loadingAction: false,

      }
    },
    computed: {
      ...mapGetters({
        globalProgramId: 'programId',
        categoryList: 'company/nomen/categoryList',
        nullCategory: 'company/nomen/nullCategory',
      }),
      localProgramId () {
        return this.programId || this.globalProgramId
      },
      selectList () {
        return this.categoryList
      },
      valueMutation: {
        get: function () {
          return this.value === null ? this.nullCategory.uuid : this.value
        },
        set: function (v) {
          this.$emit('update', v === this.nullCategory.uuid ? null : v)
        },
      },
    },
    watch: {
      programId (v) {
        this.startAction()
      },
    },
    created () {
      this.startAction()
    },

    methods: {
      ...mapActions({
        getCategoryList: 'company/nomen/getCategoryList',
      }),
      async startAction () {
        if (this.loadingAction) return
        try {
          this.loadingAction = true
          await this.getCategoryList(this.localProgramId)
          if (this.selectList.length > 0 && !this.valueMutation && this.selectFirst) this.valueMutation = this.selectList[0].id
        } catch (error) {
          console.error(error)
        } finally {
          this.loadingAction = false
        }
      },

    },
  }
</script>
